













































































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'

@Component({
  components: {
    'AppForm': ValidationObserver,
    'AppInput': BInput,
  }
})
export default class TrackingUserProfile extends Vue {
  //#region COMPUTED
  get loading() {
    return this.$store.getters.loading
  }
  get error() {
    return this.$store.getters.error
  }
  get user() {
    return this.$store.getters.user
  }
  //#endregion
  //#region DATA
  $refs!: { observer: InstanceType<typeof ValidationObserver> }
  editedItem:any = {
    username: this.user.username,
    fullname: this.user.fullname,
    oldPassword: '',
    newPassword: '',
  }
  //#endregion
  //#region METHODS
  saveItem() {
    this.$refs.observer.validate()
      .then(success => {
        if (!success) return
        this.$store.dispatch('master/trackingUser/UpdateDataProfile', this.editedItem)
      })
  }
  //#endregion
}
